.congratulations-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: radial-gradient(circle, rgb(158, 160, 161), rgba(255, 255, 255, 0));
    color: white;
    z-index: 1001;
}