body {
  /* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #11e959); */
  /* background: linear-gradient(-45deg, #ffffff, #24d53f, #e41a1a, #a1a1a1); */
  /* background-image: linear-gradient(to top, #a8edea 0%, #fed6e3 100%); */
  /* background-image: linear-gradient(to top, #fcc5e4 0%, #fda34b 15%, #ff7882 35%, #c8699e 52%, #7046aa 71%, #0c1db8 87%, #020f75 100%); */
  /* https://webgradients.com/ */
  /* background-image: linear-gradient(-225deg, #231557 0%, #44107A 29%, #FF1361 67%, #FFF800 100%); */
  /* background-image: linear-gradient(-225deg, #69EACB 0%, #EACCF8 48%, #9e95de 100%); */

  /* background-image: linear-gradient(120deg, #ffffff 25%, #82e4ed 25%, #82e4ed 50%, #ffffff 50%, #ffffff 75%, #82e4ed 75%, #82e4ed 100%); */
  /* background-image: linear-gradient(120deg, #ffffff 10%, #82e4ed 10%, #82e4ed 20%, #abdec3 20%, #abdec3 30%, #ebbcbc 30%, #ebbcbc 40%, #d68dc4 40%, #d68dc4 50%, #ffffff 50%, #ffffff 60%, #82e4ed 60%, #82e4ed 70%, #abdec3 70%, #abdec3 80%, #ebbcbc 80%, #ebbcbc 90%, #d68dc4 90%, #d68dc4 100%); */

  background-image: linear-gradient(120deg, #ffffff 6.25%, #82e4ed 6.25%, #82e4ed 12.50%, #abdec3 12.50%, #abdec3 18.75%, #ebbcbc 18.75%, #ebbcbc 25%, #d68dc4 25%, #d68dc4 31.25%, #6f59ff 31.25%, #6f59ff 37.50%, #e3d671 37.50%, #e3d671 43.75%, #fff 43.75%, #fff 50%, #ffffff 50%, #ffffff 56.25%, #82e4ed 56.25%, #82e4ed 62.50%, #abdec3 62.50%, #abdec3 68.75%, #ebbcbc 68.75%, #ebbcbc 75%, #d68dc4 75%, #d68dc4 81.25%, #6f59ff 81.25%, #6f59ff 87.50%, #e3d671 87.50%, #e3d671 93.75%, #fff 93.75%, #fff 100%);
  background-size: 100.00px 173.21px;
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100vh;
  overflow-y: hidden;
}

@keyframes gradient {
  0% {
    background-position: 50% 0%;
  }

  50% {
    background-position: 50% 100%;
  }

  100% {
    background-position: 50% 0%;
  }
}

/* Style the navigation bar */
nav {
  visibility: hidden;
  background-color: aliceblue;
  overflow: hidden;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-end;
  /* Aligns items to the right */
}

/* Style the nav list */
nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

nav ul li {
  border: none;
  background-color: transparent;
}

nav ul li a {
  display: block;
  color: #6c6c6c;
  text-decoration: none;
  transition: background-color 0.3s;
  border: black;
}

nav ul li a:hover {
  color: skyblue;
  transform: scale(1.05);
}

.card {
  background-color: transparent;
  border: none;
  padding: 1vmin;
}

.p-toast {
  background-color: white;
}

.card-main {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0.25em;
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  left: 50%;
  padding: 3vmin;
  position: fixed;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  display: inline;

}


@media (max-width: 600px) {
  .card-main {
    display: inline;
    width: 100%;
  }
}

.float-label-margin {
  margin-bottom: 1rem;
}

.h1-margin {
  margin-bottom: 1rem;
  /* Adjust the value as needed */
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.7);
  }

  70% {
    transform: scale(1.1);
    box-shadow: 0 0 10px 10px rgba(0, 123, 255, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  }
}

.pulsing-button {
  margin-top: 20px;
  padding: 15px 20px;
  font-size: 16px;
  color: white;
  background-color: #5472d4;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  animation: pulse 2s infinite;
}

.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.align-left {
  text-align: right;
}